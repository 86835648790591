// Gatsby supports TypeScript natively!
import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

export default function Example() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <div className="relative py-8 bg-white overflow-hidden">

      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto mt-6 text-gray-600">
          <h1>
            <span className="block text-base text-center text-green-500 font-semibold tracking-wide uppercase">
              Steam Seattle LLLP
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy
            </span>
          </h1>
          <p className="text-center"><strong>Last Updated June 13, 2022</strong></p>
          <br/>
          <br/>

          <p>This privacy policy discloses the privacy practices for www.seattlesteam.com . This privacy policy applies solely to information collected by this web site. It will notify you of the following:</p>
<ul className="list-disc list-inside">
    <li>What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.</li>
    <li>What choices are available to you regarding the use of your data.</li>
    <li>The security procedures in place to protect the misuse of your information.</li>
    <li>How you can correct any inaccuracies in the information.</li>
</ul>
<br/>
<strong><p>Information Collection, Use and Sharing</p></strong>
<p>We are the sole owners of the information collected on this site. We only have access to collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.</p>
<br/>
<p>We will use your information to respond to you regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future to tell you about new products or services, or changes to this privacy policy.</p>
<br/>
<p>Our site logs do generate certain kinds of non-identifying site usage data, such as the number of hits and visits to our site. This information is used for internal purposes by technical support staff to provide better services to the public and may also be provided to others. Again, the statistics contain no personal information and cannot be used to gather such information.</p>
<br/>
<strong><p>Your Access to and Control Over Information</p></strong>
<p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</p>
<br/>
<ul className="list-disc list-inside">
    <li>See what data we have about you if any</li>
    <li>Change/correct any data we have about you</li>
    <li>Have us delete any data we have about you</li>
    <li>Express any concern you have about our use of your data</li>
</ul>
<br/>
<p>Personal Information we collect from you shall include but may not be limited to your name, address, phone number and email, your payment information, the make, year and model of your vehicle and any other such information as Steam Seattle may request in order to provide you with Steam Seattle Services.</p>
<br/>
<strong><p>Cookies</p></strong>
<p>We use &ldquo;cookies&rdquo; on this site. A cookie is a piece of data stored on a site visitor&rsquo;s hard drive to help us improve your access to our site and identify repeat visitors to our site.
<br/>Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>
<br/>
<strong><p>Security</p></strong>
<p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>
<p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, customer service) are granted access to personally-identifiable information. The computers/servers in which we store personally-identifiable information are kept in a secure environment.</p>
<br/>
<strong><p>Updates</p></strong>
<p>Our Privacy Policy may change from time to time, and all updates will be posted on this page. If you feel that we are not abiding by this privacy policy, please contact us immediately</p>


        </div>
      </div>
    </div>
    </Layout>
  );
}
